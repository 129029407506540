<template>
  <Popup
    :width="width"
    :closePopupEvent="closePopupCB"
    :displayBack="displayBack"
    :heightPopup="'100%'"
    :propStyle='{margin:"2vw 0"}'
  >
    <h6 slot="header">{{ header }}</h6>
    <div class="session-exp-popup">
      <div class="progressbar-parent">
        <ul class="payment-progressbar">
          <li
            class="payment-progress"
            :class="progress.status"
            v-for="(progress, index) in progressList"
            :key="index"
          >
            {{ progress.title }}
          </li>
        </ul>
      </div>
      <div
        class="row col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 card-intermidiate"
        v-if="paymentData && paymentData.intermediate"
      >
        <!-- <div > -->
        <div v-if="intermediateList && intermediateList.length>1" class="or">
            Or
        </div>  
        <div
          class="intermidiate-div"
          v-for="(intermediate, index) in intermediateList"
          :key="index"
          :style="{order:index}"
        >
          <Intermidiate :data="intermediate.title" class="card-container" />
        </div>
      </div>
      <!-- </div> -->
      <div
        v-else-if="paymentData && paymentData.subscription"
        class="subscription-div"
      >
        <div v-if="paymentData.menuClicked">
          <h4>Subscribe</h4>
          <p>to watch the content</p>
        </div>
        <div v-else>
          <h4>Plans</h4>
        </div>

        <div class="card-intermidiate">
          <Plans fromPage="payment" @noplans='processNoplans()' />
        </div>
      </div>
      <div class="row nomargin" v-else-if="paymentData && paymentData.purchase">
        <div>
          <h4>Quality</h4>
        </div>
        <div class="col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Quality :cardContent="content" :country="country" />
        </div>
      </div>
      <div class="row nomargin" v-else-if="paymentData && paymentData.payment">
        <div
          class="col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 payment-div"
        >
          <Payment :paymentData="paymentData" />
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  name: "IntermediatePopup",
  props: ["closePopupCB", "paymentData"],
  data() {
    return {
      content: null,
      width: "65%",
      header: "",
      progressList: [],
      intermediateList: [],
      totalIntermidiate: "",
      displayBack: false,
      intermediateDisplayed: false,
      backData: "",
      ismenuClicked:false
    };
  },
  watch: {
    paymentData() {
      this.createProgressList();
    },
    displayBack() {
      console.log("display Back... ", this.displayBack);
    },
  },
  computed: {
    ...mapGetters(["appConfig", "country"]),
  },
  methods: {
    ...mapActions(["actGetContent"]),
    processNoplans(){
      if(!this.paymentData.subscription || this.paymentData.purchase)return;
      if(this.paymentData.autoAction){
        this.closePopupCB();
      }
    },
    createProgressList() {
      // this.appConfig.featureEnabled.purchase = false
      console.log("paymentData ", this.paymentData);
      this.paymentData.menuClicked=this.ismenuClicked;
      if (this.paymentData && this.paymentData.intermediate) {
        this.intermediateDisplayed = true;
        this.progressList = [
          {
            key: "paymentType",
            title: "Subscribe/ Purchase",
            status: "active-progress",
          },
          { key: "planOrQuality", title: "Plan/ Quality", status: "" },
          { key: "payment", title: "Payment", status: "" },
        ];
        this.displayBack = false;
      // } else if (
      //   this.paymentData &&
      //   (this.paymentData.subscription &&
      //     (this.paymentData.payment && !this.paymentData.purchase))
      
       } else if (
        this.paymentData &&
        (this.paymentData.subscription && !this.paymentData.purchase) ||(this.paymentData.paymentdata && this.paymentData.paymentdata.frompage === "plans")
      ) {
        this.progressList = [
          {
            key: "paymentType",
            title: "Subscribe/ Purchase",
            status: "active-progress",
          },
          { key: "plan", title: "Select Plan", status: "active-progress" },
          { key: "payment", title: "Payment", status: "" },
        ];
        if (this.paymentData.payment) {
          this.progressList[2].status = "active-progress";
        }
        this.displayBack = true;

        let config = this.appConfig;
        // config.featureEnabled.purchase = false;
        console.log(this.paymentData.displayContent);

        if (
          (!config.featureEnabled.purchase &&
            config.featureEnabled.subscription) ||
          this.paymentData.menuClicked
        ) {
          this.progressList.shift();
          this.displayBack = false;
        }

        if (
          (this.paymentData.payment || this.paymentData.displayContent === 2) &&
          !this.intermediateDisplayed
        ) {
          // this.progressList.shift();
          if (this.appConfig && this.paymentData.payment) {
            this.displayBack = true;
          } else {
            this.displayBack = false;
          }
        }

        if (this.paymentData.menuClicked) {
          // this.progressList.shift();
          this.displayBack = false;
          if (this.paymentData.payment) {
            this.displayBack = true;
          } else {
            this.displayBack = false;
          }
        }
      } else if (
        this.paymentData &&
        (this.paymentData.purchase || this.paymentData.payment)
      ) {
        console.log("enterd");
        this.progressList = [
          {
            key: "paymentType",
            title: "Subscribe/ Purchase",
            status: "active-progress",
          },
          {
            key: "quality",
            title: "Select Quality",
            status: "active-progress",
          },
          { key: "payment", title: "Payment", status: "" },
        ];
        if (this.paymentData.payment) {
          this.progressList[2].status = "active-progress";
        }
        this.displayBack = true;

        let config = this.appConfig;
        // config.featureEnabled.subscription = false;
        if (
          config &&
          config.featureEnabled &&
          !config.featureEnabled.subscription &&
          config.featureEnabled.purchase
        ) {
          console.log("entered shift")
          this.progressList.shift();
          if (this.appConfig && this.paymentData.payment) {
            this.displayBack = true;
          } else {
            this.displayBack = false;
          }
        }
      }
    },
    createIntermidiateList() {
      this.intermediateList = [{ title: "Subscribe" }, { title: "Purchase" }];
      this.totalIntermidiate = this.intermediateList.length - 1;
    },

    getContent() {
      if (!this.paymentData.content || this.paymentData.content.price)
        return Promise.resolve();
      let data = this.paymentData.content.contentid;
      return this.actGetContent(data)
        .then((response) => {
          if (!response.error) {
            this.content = response;
          }
        })
        .catch((error) => {
          console.log("error ", error);
          if (error.status === 401) {
            this.commonAutoLogin(this.plansSetValue);
            return;
          }
        });
    },
    checkDisplayFun(data) {
      console.log("enterd ", data)
      if (data.data === "payment") {
        this.paymentData.payment = true;
        this.paymentData.subscription = false;
        this.paymentData.intermediate = false;
        this.paymentData.purchase = false;
        this.displayBack = true;
        this.paymentData.paymentdata = data;

        if (this.paymentData.paymentdata.frompage === "plans") {
          this.backData = "subscription";
        } else {
          this.backData = "purchase";
        }

        this.createProgressList();
      } else if (data === "Purchase") {
        data = {
          purchase: true,
          content: this.content,
        };
        this.backData = "intermidiate";

        EventBus.$emit("paymentInitPopup", data);
      } else if (data === "Subscribe") {
        data = {
          subscription: true,
          purchase:false,
          content: this.content,
        };
        this.backData = "intermidiate";
        EventBus.$emit("paymentInitPopup", data);
      }
    },
  },
  mounted() {
    EventBus.$on("checkDisplayPayment", this.checkDisplayFun);

    EventBus.$on("backPopup", (data) => {
      // if (data && this.paymentData.menuClicked) {
      if (data) {
        if (this.backData === "subscription") {
          this.paymentData.subscription = true;
          this.paymentData.purchase = false;
          this.paymentData.payment = false;
          this.backData = "intermidiate";
          this.paymentData.menuClicked=this.ismenuClicked;
          // this.paymentData.intermediate = true;
        } else if (this.backData === "purchase") {
          this.paymentData.purchase = true;
          this.paymentData.subscription = false;
          this.paymentData.payment = false;
          this.backData = "intermidiate";
          // this.paymentData.intermediate = true;
        } else if (this.backData === "intermidiate") {
          // this.paymentData.purchase = true;
          this.paymentData.intermediate = true;
        } else {
          this.paymentData.subscription = true;
          this.paymentData.payment = false;
          this.paymentData.displayContent = 2;
        }
        this.createProgressList();
      }
    });
  },
  created() {
    this.ismenuClicked=this.paymentData.menuClicked;
    this.content = this.paymentData.content;
    this.getContent().then(() => {
      if (
        this.paymentData.paymentdata &&
        this.paymentData.paymentdata.frompage &&
        this.paymentData.paymentdata.frompage === "plans"
      ) {
        // this.createProgressList();
        // this.createIntermidiateList();
      } else {
        this.createProgressList();
        this.createIntermidiateList();
      }
    });
  },
   beforeDestroy() {
    EventBus.$off("checkDisplayPayment", this.checkDisplayFun);
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    Intermidiate: () =>
      import(
        /* webpackChunkName: "intermidiate popup" */ "@/components/Popups/payment/intermidiate.vue"
      ),
    Plans: () =>
      import(
        /* webpackChunkName: "plans Popup" */ "@/components/Profile/plans.vue"
      ),
    Quality: () =>
      import(
        /* webpackChunkName: "quality Popup" */ "@/components/Popups/payment/quality.vue"
      ),
    Payment: () =>
      import(
        /* webpackChunkName: "payment Popup" */ "@/components/Popups/payment/payment.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.nomargin {
  margin: 0 !important;
}
.session-exp-popup {
  // margin: 5% 10%;
  margin: 2% 5%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  //max-height: 60vh;
  position: relative;
}
.submit-btns {
  margin: 25% 5% 0% 18%;
  width: 70%;
}
.subs-btns {
  text-align: center;
}
.btn-login {
  width: 50%;
  margin-top: 10%;
}
.payment-div {
  padding: 0%;
}
.card-intermidiate {
  // display: flex;
  // justify-content: center;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin: 5% auto;
  padding: 0%;
  // margin-left: 5%;

  .intermidiate-div {
    display: flex;
    // margin: 2%;
    width: 30%;
  }
   .or {
      width: 10%;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      text-align: center;
      order:1;
      margin: auto 0;
    }
}
h4 {
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size:1.5rem;
  color: $font-clr-white;
  text-align: center;
  margin-top: 5%;
}
.subscription-div {
  text-align: center;
}
.card-container {
  display: flex;
  .containers {
    width: 45%;
    background: $clr-color-black-2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $clr-color-black-3;
    border: 3px solid $clr-bg-gray-dark;
    border-radius: 12px;
    text-align: center;
    padding: 10% 5%;
    h4 {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      color: $font-clr-white;
    }
  }
  .left-container {
    float: left;
  }
  .right-container {
    float: right;
  }
  .or {
    width: 10%;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    text-align: center;
    margin: auto;
  }
}
.progressbar-parent {
  position: sticky;
  padding: 3px 0;
  background: $clr-bg-gray-dark;
  z-index: 1000;
  top: -3px;
}
.payment-progressbar {
  // counter-reset: step;
  display: flex;
  justify-content: center;
}
.payment-progressbar li {
  list-style-type: none;
  // width: 25%;
  width: 33%;
  float: left;
  font-size: 0.9rem;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  color: $font-clr-white;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  word-break: break-word;
}

.payment-progressbar li::before {
  content: counter(step);
  counter-increment: step;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: $clr-bg-gray-light-1;
}
.payment-progressbar li::after {
  content: "";
  position: absolute;
  width: 96%;
  height: 1px;
  background-color: $clr-bg-gray-light-7;
  top: 10px;
  left: -50%;
  z-index: -9;
}
.payment-progressbar li:first-child:after {
  content: none;
}
.payment-progressbar li.active-progress {
  color: $font-clr-white;
}
.payment-progressbar li.active-progress:before {
  background-color: $theme-primary;
  color: $font-clr-white;
}
.payment-progress {
  color: $font-clr-white;
}
li.payment-progress.active-progress:nth-child(2) {
  box-shadow: -9vw 10px 1px $theme-primary;
  height: 1px;
}
li.payment-progress.active-progress:nth-child(3) {
  box-shadow: -9vw 10px 1px $theme-primary;
  height: 1px;
}

@include breakpoint(max600) {
  .payment-progressbar li.active-progress {
    color: $font-clr-white;
  }
  .card-intermidiate{
    flex-flow:column nowrap;
    align-items: center;
    .intermidiate-div{
      width:70%;
    }
  }
}
</style>